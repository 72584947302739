<template>
  <div v-if="broadcast" class="d-block" style="margin-bottom: 200px;">
    <EditSMS v-if="broadcast.provider === 'sms'" ref="smsComponent" :object="broadcast"></EditSMS>
    <EditEmail v-else ref="emailComponent" :object="broadcast"></EditEmail>
  </div>
  <div v-else class="text-center">
    <b-spinner></b-spinner>
  </div>
</template>
  
<script>
  import EditEmail from './EditEmail'
  import EditSMS from './EditSMS'
  import moment from 'moment-timezone'

  export default {
    components: {
      EditEmail,
      EditSMS
    },
  
    data() {
      return {
        loadingBroadcast: false,
        broadcast: null,
        listKeywords: [],
      }
    },

    computed: {
      user() {
        return this.$store.getters['auth/user']
      },
    },

    mounted() {
      this.getKeywords()
    },

    methods: {
      getBroadcast() {
        this.loadingBroadcast = true

        this.$store
          .dispatch('broadcast/find', this.$route.params.id)
          .then((data) => {
            if (data.status === 'scheduled')
            {
              data.send_type = 'later'
              if (!data.scheduled_at)
              {
                data.scheduled_at = moment();
                data.scheduled_at.set({minute:0,second:0})
              }
            } else {
              if (data.scheduled_at)
              {
                data.send_type = 'later'
              } else {
                data.send_type = 'now'
              }
            }

            if (data.provider === 'email') {
              data.options = {
                width: 750,
                align: 'center',
                ...data.options
              }
            }


            data.date = moment(data.scheduled_at).tz(data.timezone || this.user.business.timezone).format('YYYY-MM-DD')
            data.time = moment(data.scheduled_at).tz(data.timezone || this.user.business.timezone).format('HH:mm:00')
            this.broadcast = JSON.parse(JSON.stringify(data))
            this.broadcast.exclude_keywords = this.listKeywords.filter(c => this.broadcast.exclude_keywords.includes(c.id))
            // this.constBroadcast = JSON.parse(JSON.stringify(this.broadcast))
            this.loadingBroadcast = false
          })
          .catch(() => {
            this.$router.push({ name: 'business.broadcasts.index' })
            this.loadingBroadcast = false
          })
      },

      getKeywords() {
        this.$store
          .dispatch('coupon/getKeywordsWithoutDemo')
          .then((keywords) => {
            this.keywords = keywords.map(c => c.name)
            this.listKeywords = keywords
            this.getBroadcast()
          })
          .catch(() => {
          })
      },
    },

    beforeRouteLeave (to, from , next) {
      if (this.$refs.smsComponent) {
        this.$refs.smsComponent.beforeRouteLeave(to, from, next)
      } else {
        this.$refs.emailComponent.beforeRouteLeave(to, from, next)
      }
    }
  }
  </script>