<template>
  <div class="broadcast-section">
    <WizardForm v-if="broadcast" page-type="update" :broadcast="broadcast" @broadcast-changed="broadcastChanged">
    </WizardForm>
  </div>
</template>

<script>
import WizardForm from './WizardForm'

export default {
  components: {
    WizardForm,
  },

  props: {
    object: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      broadcast: null,
      loadingBroadcast: false,
      listKeywords: [],
      constBroadcast: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.getBroadcast()
  },

  methods: {
    broadcastChanged(broadcast) {
      this.broadcast = JSON.parse(JSON.stringify(broadcast))
      this.constBroadcast = JSON.parse(JSON.stringify(broadcast))
    },

    getBroadcast() {
      this.broadcast = JSON.parse(JSON.stringify(this.object))
      this.constBroadcast = JSON.parse(JSON.stringify(this.broadcast))
    },

    beforeRouteLeave(to, from, next) {
      let diff = this.difference(this.broadcast, this.constBroadcast)

      if (Object.keys(diff).length) {
        const answer = window.confirm('Are you sure you want to leave without saving?')
        if (answer) {
          next()
        } else {
          next(false)
        }
      } else {
        next()
      }
    }
  },
}
</script>
